import { computed } from "vue";
import { getGlobalTranslation } from "../../i18n";

export enum GuestLanguage {
  NONE = 0,
  GERMAN = 1,
  ITALIAN = 2,
  ENGLISH = 3,
  BULGARIAN = 4,
  CROATIAN = 5,
  CZECH = 6,
  DANISH = 7,
  DUTCH = 8,
  ESTONIAN = 9,
  FINNISH = 10,
  FRENCH = 11,
  GREEK = 12,
  HUNGARIAN = 13,
  IRISH = 14,
  LATVIAN = 15,
  LITHUANIAN = 16,
  POLISH = 17,
  PORTUGUESE = 18,
  ROMANIAN = 19,
  SLOVAK = 20,
  SLOVENIAN = 21,
  SPANISH = 22,
  SWEDISH = 23,
  RUSSIAN = 25,
  JAPANESE = 26,
}

export const guestLanguageSelectOptions = computed(() => {
  const array = [];
  for (const [key, value] of Object.entries(GuestLanguage)) {
    if (typeof value === "number" && value !== GuestLanguage.NONE) {
      array.push({
        value,
        label: getGlobalTranslation({ message: `language.${key}` }),
      });
    }
  }
  return array;
});
