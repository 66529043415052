import Swal from "sweetalert2";
import { createRouter, createWebHistory, RouteLocation, RouteRecordRaw } from "vue-router";

import { DatabaseAction } from "../activityLog/enum.databaseAction";
import { isRefreshNeeded, updateServiceWorker } from "../components/ServiceWorkerUpdatePopupComponent";
import { useApp } from "../composition-functions/app";
import { usePortal } from "../composition-functions/portal";
import { useUser } from "../composition-functions/user";
import { UserType } from "../composition-functions/user/UserType";
import { config } from "../config/config";
import { GuestMessageType } from "../connect/enum.guestMessageType";
import { getGlobalTranslation } from "../i18n";
import { useMandant } from "../mandant/service.mandant";
import { setToastMessage } from "../util/SweetalertHelper";

export interface ActivityLogMetadata {
  users?: string[];
  entities?: string[];
  databaseActions?: DatabaseAction[];
  entityPrimaryKeys?: string[];
}

declare module "vue-router" {
  interface RouteMeta {
    title?: (route: RouteLocation) => undefined | string;
    parent?: (route: RouteLocation) => undefined | RouteLocationNamedRaw;
    docsUrl?: (route: RouteLocation) => undefined | string;
    activityLogs: (route: RouteLocation) => undefined | ActivityLogMetadata;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/_Login_View.vue"),
    meta: {
      doesNotRequireAuthentication: true,
      activityLogs: () => {
        return undefined;
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/_Logout_View.vue"),
    meta: {
      doesNotRequireAuthentication: true,
      activityLogs: () => {
        return undefined;
      },
    },
  },
  {
    path: "/",
    component: () => import("../views/_Authenticated_View.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("../views/_Home_View.vue"),
      },
      {
        path: "/admin_dashboard",
        name: "adminDashboard",
        component: () => import("../views/Admin_DashboardView.vue"),
        meta: {
          title: () => {
            return getGlobalTranslation({ message: "pages.admin.dashboard.title" });
          },
          activityLogs: () => {
            return undefined;
          },
        },
      },
      {
        path: "/activity_log",
        name: "activityLog",
        component: () => import("../activityLog/views/ActivityLog_ListView.vue"),
        meta: {
          title: () => {
            return getGlobalTranslation({ message: "pages.activityLog.title" });
          },
          parent: () => {
            return {
              name: "adminDashboard",
            };
          },
          activityLogs: () => {
            return undefined;
          },
        },
      },
      {
        path: "releases",
        name: "releaseNotesPublic",
        component: () => import("../releaseNote/views/ReleaseNote_PublicList.vue"),
        meta: {
          title: () => {
            return getGlobalTranslation({ message: "pages.releaseNotes.releaseNotes.list.title" });
          },
          parent: () => {
            return {
              name: "home",
            };
          },
          activityLogs: () => {
            return undefined;
          },
        },
      },
      {
        path: "/mandants",
        component: () => import("../mandant/views/_Mandants_View.vue"),
        children: [
          {
            path: "list",
            name: "mandantsList",
            component: () => import("../mandant/views/Mandants_ListView.vue"),
            meta: {
              title: () => {
                return getGlobalTranslation({ message: "pages.mandant.list.title" });
              },
              parent: () => {
                const { userType } = useUser();
                if (userType.value === UserType.ADMIN || userType.value === UserType.SUPER_ADMIN) {
                  return {
                    name: "adminDashboard",
                  };
                } else {
                  return undefined;
                }
              },
              activityLogs: () => {
                return {
                  entities: ["mandants"],
                };
              },
            },
          },
          {
            path: ":mandantId",
            component: () => import("../mandant/views/_Mandant_View.vue"),
            props(route) {
              return {
                mandantId: parseInt(route.params.mandantId as string),
              };
            },
            children: [
              {
                path: "dashboard",
                name: "mandantDashboard",
                component: () => import("../mandant/views/Mandant_DashboardView.vue"),
                meta: {
                  title: () => {
                    return useMandant().currentMandant.value?.name;
                  },
                  parent: () => {
                    const { userType } = useUser();
                    if (
                      userType.value === UserType.ADMIN ||
                      userType.value === UserType.SUPER_ADMIN ||
                      userType.value === UserType.NORMAL_MULTIPLE
                    ) {
                      return {
                        name: "mandantsList",
                      };
                    } else {
                      return undefined;
                    }
                  },
                  activityLogs: () => {
                    return undefined;
                  },
                },
              },
              {
                path: "edit",
                name: "mandantEdit",
                component: () => import("../mandant/views/Mandant_EditView.vue"),
                meta: {
                  title: () => {
                    return getGlobalTranslation({ message: "pages.mandant.edit.title" });
                  },
                  parent: () => {
                    return {
                      name: "mandantDashboard",
                    };
                  },
                  activityLogs: (currentRoute) => {
                    return {
                      entityPrimaryKeys: convertStringToStringArray(currentRoute.params.mandantId),
                      entities: ["mandants"],
                    };
                  },
                },
              },
              {
                path: "items",
                component: () => import("../views/_Items_View.vue"),
                children: [
                  {
                    path: "dashboard",
                    name: "itemsDashboard",
                    component: () => import("../views/Item_EditView.vue"),
                    meta: {
                      title: () => {
                        return getGlobalTranslation({ message: "pages.fileManagement.title" });
                      },
                      parent: () => {
                        return {
                          name: "mandantDashboard",
                        };
                      },
                      activityLogs: () => {
                        return {
                          entities: ["item"],
                        };
                      },
                    },
                  },
                ],
              },
              {
                path: "modules",
                component: () => import("../views/_Modules_View.vue"),
                children: [
                  {
                    path: "edit",
                    name: "modulesEdit",
                    component: () => import("../views/Modules_EditView.vue"),
                    meta: {
                      docsUrl() {
                        return getLocalizedDocumentationUrl("/mandants/modules");
                      },
                      title: () => {
                        return getGlobalTranslation({ message: "pages.module.list.title" });
                      },
                      parent: () => {
                        return {
                          name: "mandantDashboard",
                        };
                      },
                      activityLogs: () => {
                        return undefined;
                      },
                    },
                  },
                ],
              },
              {
                path: "companies",
                component: () => import("../company/views/_Company_View.vue"),
                children: [
                  {
                    path: "list",
                    name: "companiesList",
                    component: () => import("../company/views/Company_ListView.vue"),
                    meta: {
                      docsUrl() {
                        return getLocalizedDocumentationUrl("/mandants/companies");
                      },
                      title: () => {
                        return getGlobalTranslation({ message: "pages.company.list.title" });
                      },
                      parent: () => {
                        return {
                          name: "mandantDashboard",
                        };
                      },
                      activityLogs: () => {
                        return {
                          entities: ["company"],
                        };
                      },
                    },
                  },
                  {
                    path: ":companyId",
                    name: "companyEdit",
                    component: () => import("../company/views/Company_EditView.vue"),
                    meta: {
                      docsUrl() {
                        return getLocalizedDocumentationUrl("/mandants/companies");
                      },
                      title: (currentRoute) => {
                        return getEditViewTitle("companyId", currentRoute);
                      },
                      parent: () => {
                        return {
                          name: "companiesList",
                        };
                      },
                      activityLogs: (currentRoute) => {
                        return {
                          entityPrimaryKeys: convertStringToStringArray(currentRoute.params.companyId),
                          entities: ["company"],
                        };
                      },
                    },
                  },
                ],
              },
              {
                path: "components",
                component: () => import("../views/_Component_View.vue"),
                children: [
                  {
                    path: "list",
                    name: "componentsList",
                    component: () => import("../views/Components_ListView.vue"),
                    meta: {
                      docsUrl() {
                        return getLocalizedDocumentationUrl("/mandants/components");
                      },
                      title: () => {
                        return getGlobalTranslation({ message: "pages.component.list.title" });
                      },
                      parent: () => {
                        return {
                          name: "mandantDashboard",
                        };
                      },
                      activityLogs: () => {
                        return {
                          entities: ["component"],
                        };
                      },
                    },
                  },
                  {
                    path: ":componentIdOrComponentType",
                    name: "componentEdit",
                    component: () => import("../views/Component_EditView.vue"),
                    meta: {
                      docsUrl() {
                        return getLocalizedDocumentationUrl("/mandants/components");
                      },
                      title: (route) => {
                        const id = parseInt(route.params.componentIdOrComponentType as string);
                        if (Number.isNaN(id)) {
                          return (
                            getGlobalTranslation({ message: `modules.${route.params.componentIdOrComponentType as string}` }) +
                            " - " +
                            getGlobalTranslation({ message: "pages.edit" })
                          );
                        }
                        return id === 0
                          ? getGlobalTranslation({ message: "pages.new" })
                          : getGlobalTranslation({ message: "pages.edit" });
                      },
                      parent: (route) => {
                        const id = parseInt(route.params.componentIdOrComponentType as string);
                        if (Number.isNaN(id)) {
                          return {
                            name: "mandantDashboard",
                          };
                        } else {
                          return {
                            name: "componentsList",
                          };
                        }
                      },
                      activityLogs: () => {
                        return undefined;
                      },
                    },
                  },
                ],
              },
              {
                path: "externalSystems",
                component: () => import("../views/_ExternalSystems_View.vue"),
                children: [
                  {
                    path: "list",
                    name: "externalSystemsList",
                    component: () => import("../views/ExternalSystems_ListView.vue"),
                    meta: {
                      docsUrl() {
                        return getLocalizedDocumentationUrl("/mandants/external-systems");
                      },
                      title: () => {
                        return getGlobalTranslation({ message: "pages.externalSystem.list.title" });
                      },
                      parent: () => {
                        return {
                          name: "mandantDashboard",
                        };
                      },
                      activityLogs: () => {
                        return {
                          entities: ["external_system"],
                        };
                      },
                    },
                  },
                  {
                    path: ":externalSystemId",
                    name: "externalSystemEdit",
                    component: () => import("../views/ExternalSystem_EditView.vue"),
                    meta: {
                      docsUrl() {
                        return getLocalizedDocumentationUrl("/mandants/external-systems");
                      },
                      title: (currentRoute) => {
                        return getEditViewTitle("externalSystemId", currentRoute);
                      },
                      parent: () => {
                        return {
                          name: "externalSystemsList",
                        };
                      },
                      activityLogs: (currentRoute) => {
                        return {
                          entityPrimaryKeys: convertStringToStringArray(currentRoute.params.externalSystemId),
                          entities: ["external_system"],
                        };
                      },
                    },
                  },
                ],
              },
              {
                path: "paymentSystems",
                component: () => import("../payment/views/_PaymentSystem_View.vue"),
                children: [
                  {
                    path: "list",
                    name: "paymentSystemsList",
                    component: () => import("../payment/views/PaymentSystem_ListView.vue"),
                    meta: {
                      title: () => {
                        return getGlobalTranslation({ message: "pages.payment.paymentSystem.list.title" });
                      },
                      parent: () => {
                        return {
                          name: "mandantDashboard",
                        };
                      },
                      activityLogs: () => {
                        return {
                          entities: ["payment_system"],
                        };
                      },
                    },
                  },
                  {
                    path: ":paymentSystemId",
                    name: "paymentSystemEdit",
                    component: () => import("../payment/views/PaymentSystem_EditView.vue"),
                    meta: {
                      title: (currentRoute) => {
                        return getEditViewTitle("paymentSystemId", currentRoute);
                      },
                      parent: () => {
                        return {
                          name: "paymentSystemsList",
                        };
                      },
                      activityLogs: (currentRoute) => {
                        return {
                          entityPrimaryKeys: convertStringToStringArray(currentRoute.params.paymentSystemId),
                          entities: ["payment_system"],
                        };
                      },
                    },
                  },
                ],
              },
              {
                path: "discountCodes",
                component: () => import("../discountCode/views/_DiscountCode_View.vue"),
                children: [
                  {
                    path: "list",
                    name: "discountCodesList",
                    component: () => import("../discountCode/views/DiscountCode_ListView.vue"),
                    meta: {
                      title: () => {
                        return getGlobalTranslation({ message: "pages.discountCode.list.title" });
                      },
                      parent: () => {
                        return {
                          name: "mandantDashboard",
                        };
                      },
                      activityLogs: () => {
                        return {
                          entities: ["discount_code"],
                        };
                      },
                    },
                  },
                  {
                    path: ":discountCodeId",
                    name: "discountCodeEdit",
                    component: () => import("../discountCode/views/DiscountCode_EditView.vue"),
                    meta: {
                      title: (currentRoute) => {
                        return getEditViewTitle("discountCodeId", currentRoute);
                      },
                      parent: () => {
                        return {
                          name: "discountCodesList",
                        };
                      },
                      activityLogs: (currentRoute) => {
                        return {
                          entityPrimaryKeys: convertStringToStringArray(currentRoute.params.discountCodeId),
                          entities: ["discount_code"],
                        };
                      },
                    },
                  },
                ],
              },
              {
                path: "widgets",
                component: () => import("../views/_Widgets_View.vue"),
                children: [
                  {
                    path: "list",
                    name: "widgetsList",
                    component: () => import("../views/Widget_ListView.vue"),
                    meta: {
                      title: () => {
                        return getGlobalTranslation({ message: "pages.widget.list.title" });
                      },
                      parent: () => {
                        return {
                          name: "mandantDashboard",
                        };
                      },
                      activityLogs: () => {
                        return {
                          entities: ["widget"],
                        };
                      },
                    },
                  },
                  {
                    path: ":widgetId",
                    name: "widgetEdit",
                    component: () => import("../views/Widget_EditView.vue"),
                    meta: {
                      title: (currentRoute) => {
                        return getEditViewTitle("widgetId", currentRoute);
                      },
                      parent: () => {
                        return {
                          name: "widgetsList",
                        };
                      },
                      activityLogs: (currentRoute) => {
                        return {
                          entityPrimaryKeys: convertStringToStringArray(currentRoute.params.widgetId),
                          entities: ["widget"],
                        };
                      },
                    },
                  },
                ],
              },
              {
                path: "greetings",
                component: () => import("../greeting/views/_Greetings_View.vue"),
                children: [
                  {
                    path: "list",
                    name: "greetingsList",
                    component: () => import("../greeting/views/Greetings_ListView.vue"),
                    meta: {
                      title: () => {
                        return getGlobalTranslation({ message: "pages.greeting.list.title" });
                      },
                      parent: () => {
                        return {
                          name: "mandantDashboard",
                        };
                      },
                      activityLogs: () => {
                        return {
                          entities: ["greeting"],
                        };
                      },
                    },
                  },
                  {
                    path: ":greetingId",
                    name: "greetingEdit",
                    component: () => import("../greeting/views/Greeting_EditView.vue"),
                    meta: {
                      title: (currentRoute) => {
                        return getEditViewTitle("greetingId", currentRoute);
                      },
                      parent: () => {
                        return {
                          name: "greetingsList",
                        };
                      },
                      activityLogs: (currentRoute) => {
                        return {
                          entityPrimaryKeys: convertStringToStringArray(currentRoute.params.greetingId),
                          entities: ["greeting"],
                        };
                      },
                    },
                  },
                ],
              },
              {
                path: "voucher",
                component: () => import("../voucher/views/_Voucher_BaseView.vue"),
                children: [
                  {
                    path: "categories",
                    component: () => import("../voucher/views/_VoucherCategory_View.vue"),
                    children: [
                      {
                        path: "list",
                        name: "voucherCategoriesList",
                        component: () => import("../voucher/views/VoucherCategory_ListView.vue"),
                        meta: {
                          title: () => {
                            return getGlobalTranslation({ message: "pages.voucher.voucherCategory.list.title" });
                          },
                          parent: () => {
                            return {
                              name: "mandantDashboard",
                            };
                          },
                          activityLogs: () => {
                            return {
                              entities: ["voucher_category"],
                            };
                          },
                        },
                      },
                      {
                        path: ":voucherCategoryId",
                        name: "voucherCategoryEdit",
                        component: () => import("../voucher/views/VoucherCategory_EditView.vue"),
                        meta: {
                          parent: () => {
                            return {
                              name: "voucherCategoriesList",
                            };
                          },
                          title: () => {
                            return getGlobalTranslation({ message: "pages.edit" });
                          },
                          activityLogs: (currentRoute) => {
                            return {
                              entityPrimaryKeys: convertStringToStringArray(currentRoute.params.voucherCategoryId),
                              entities: ["voucher_category"],
                            };
                          },
                        },
                      },
                    ],
                  },
                  {
                    path: "voucherElements",
                    component: () => import("../voucher/views/_VoucherElement_View.vue"),
                    children: [
                      {
                        path: "list",
                        name: "voucherElementsList",
                        component: () => import("../voucher/views/VoucherElement_ListView.vue"),
                        meta: {
                          title: () => {
                            return getGlobalTranslation({ message: "pages.voucher.voucherElement.list.title" });
                          },
                          parent: () => {
                            return {
                              name: "mandantDashboard",
                            };
                          },
                          activityLogs: () => {
                            return {
                              entities: ["voucher_element"],
                            };
                          },
                        },
                      },
                      {
                        path: ":voucherElementId",
                        name: "voucherElementEdit",
                        component: () => import("../voucher/views/VoucherElement_EditView.vue"),
                        meta: {
                          parent: () => {
                            return {
                              name: "voucherElementsList",
                            };
                          },
                          title: () => {
                            return getGlobalTranslation({ message: "pages.edit" });
                          },
                          activityLogs: (currentRoute) => {
                            return {
                              entityPrimaryKeys: convertStringToStringArray(currentRoute.params.voucherElementId),
                              entities: ["voucher_element"],
                            };
                          },
                        },
                      },
                    ],
                  },
                  {
                    path: "vouchers",
                    component: () => import("../voucher/views/_Voucher_View.vue"),
                    children: [
                      {
                        path: "list",
                        name: "vouchersList",
                        component: () => import("../voucher/views/Voucher_ListView.vue"),
                        meta: {
                          title: () => {
                            return getGlobalTranslation({ message: "pages.voucher.voucher.list.title" });
                          },
                          parent: () => {
                            return {
                              name: "mandantDashboard",
                            };
                          },
                          activityLogs: () => {
                            return {
                              entities: ["voucher"],
                            };
                          },
                        },
                      },
                      {
                        path: ":voucherId",
                        name: "voucherEdit",
                        component: () => import("../voucher/views/Voucher_EditView.vue"),
                        meta: {
                          parent: () => {
                            return {
                              name: "vouchersList",
                            };
                          },
                          title: () => {
                            return getGlobalTranslation({ message: "pages.edit" });
                          },
                          activityLogs: (currentRoute) => {
                            return {
                              entityPrimaryKeys: convertStringToStringArray(currentRoute.params.voucherId),
                              entities: ["voucher"],
                            };
                          },
                        },
                      },
                    ],
                  },
                  {
                    path: "voucherOrder",
                    component: () => import("../voucher/views/_OrderedVoucher_View.vue"),
                    children: [
                      {
                        path: "list",
                        name: "orderedVouchersList",
                        component: () => import("../voucher/views/OrderedVoucher_ListView.vue"),
                        meta: {
                          title: () => {
                            return getGlobalTranslation({ message: "pages.voucher.orderedVoucher.list.title" });
                          },
                          parent: () => {
                            return {
                              name: "mandantDashboard",
                            };
                          },
                          activityLogs: () => {
                            return {
                              entities: ["voucher_order"],
                            };
                          },
                        },
                      },
                      {
                        path: ":orderedVoucherId",
                        name: "orderedVoucherEdit",
                        component: () => import("../voucher/views/OrderedVoucher_EditView.vue"),
                        meta: {
                          parent: () => {
                            return {
                              name: "orderedVouchersList",
                            };
                          },
                          title: () => {
                            return getGlobalTranslation({ message: "pages.edit" });
                          },
                          activityLogs: (currentRoute) => {
                            return {
                              entityPrimaryKeys: convertStringToStringArray(currentRoute.params.orderedVoucherId),
                              entities: ["voucher_order"],
                            };
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: "checklist",
                name: "checklist",
                meta: {
                  parent: () => {
                    return {
                      name: "mandantDashboard",
                    };
                  },
                  title: () => getGlobalTranslation({ message: "message.checklist" }),
                  activityLogs: () => {
                    return {
                      entities: ["checklist_info"],
                    };
                  },
                },
                component: () => import("../checklist/views/Checklist_View.vue"),
              },
              {
                path: "notifications",
                name: "notifications",
                meta: {
                  parent: () => {
                    return {
                      name: "mandantDashboard",
                    };
                  },
                  title: () => getGlobalTranslation({ message: "message.notifications" }),
                  activityLogs: () => {
                    return undefined;
                  },
                },
                component: () => import("../notification/views/Notification_View.vue"),
              },
              {
                name: "connect",
                path: "connect",
                component: () => import("../connect/views/_Connect_View.vue"),
                children: [
                  {
                    path: "mail",
                    children: [
                      {
                        path: "baseLayout",
                        name: "baseLayout",
                        component: () => import("../connect/mail/views/MailLayout_EditView.vue"),
                        meta: {
                          title: () => {
                            return getGlobalTranslation({ message: "pages.connect.mail.layout.edit.title" });
                          },
                          parent: () => {
                            return {
                              name: "mandantDashboard",
                            };
                          },
                          activityLogs: (currentRoute) => {
                            return {
                              entityPrimaryKeys: convertStringToStringArray(currentRoute.params.mandantId),
                              entities: ["connect_mail_layout"],
                            };
                          },
                        },
                      },
                      {
                        path: "mailContent/dashboard",
                        name: "mailContentDashboard",
                        component: () => import("../connect/mail/views/MailTemplate_DashboardView.vue"),
                        meta: {
                          title: () => {
                            return getGlobalTranslation({ message: "pages.connect.mail.mailTemplateConfig.dashboard.title" });
                          },
                          parent: () => {
                            return {
                              name: "mandantDashboard",
                            };
                          },
                          activityLogs: () => {
                            return undefined;
                          },
                        },
                      },
                      {
                        path: "mailContent/:messageType",
                        name: "mailContentConfig",
                        component: () => import("../connect/mail/views/MailTemplateConfig_EditView.vue"),
                        meta: {
                          title: () => {
                            return getGlobalTranslation({ message: "pages.connect.mail.mailTemplateConfig.edit.title" });
                          },
                          parent: () => {
                            return {
                              name: "mailContentDashboard",
                            };
                          },
                          docsUrl() {
                            switch (router.currentRoute.value.params.messageType) {
                              case GuestMessageType.NEWSLETTER_SYNC_DOUBLE_OPT_IN:
                                return getLocalizedDocumentationUrl(
                                  "/mandants/components/connect/e-mail-content/newsletter-sync/double-opt-in",
                                );
                              case GuestMessageType.PAYMENT_CONFIRMATION_BANK_TRANSFER:
                                return getLocalizedDocumentationUrl(
                                  "/mandants/components/connect/e-mail-content/payment/information-bank-transfer",
                                );
                              case GuestMessageType.PAYMENT_CONFIRMATION_ON_SITE:
                                return getLocalizedDocumentationUrl(
                                  "/mandants/components/connect/e-mail-content/payment/information-on-site",
                                );
                              case GuestMessageType.REQUEST_GUEST:
                                return getLocalizedDocumentationUrl(
                                  "/mandants/components/connect/e-mail-content/request/confirmation",
                                );
                              case GuestMessageType.VOUCHER_GUEST_MAIL:
                                return getLocalizedDocumentationUrl(
                                  "/mandants/components/connect/e-mail-content/voucher/confirmation-e-mail",
                                );
                              case GuestMessageType.VOUCHER_GUEST_POST:
                                return getLocalizedDocumentationUrl(
                                  "/mandants/components/connect/e-mail-content/voucher/confirmation-post",
                                );
                              default:
                                return undefined;
                            }
                          },
                          activityLogs: () => {
                            return undefined;
                          },
                        },
                      },
                      {
                        path: "salutations",
                        name: "mandantSalutations",
                        component: () => import("../mandantSalutation/views/MandantSalutation_EditView.vue"),
                        meta: {
                          docsUrl() {
                            return getLocalizedDocumentationUrl("/mandants/components/connect/salutations");
                          },
                          title: () => {
                            return getGlobalTranslation({ message: "pages.mandantSalutation.edit.title" });
                          },
                          parent: () => {
                            return {
                              name: "mandantDashboard",
                            };
                          },
                          activityLogs: () => {
                            return {
                              entities: ["mandantsalutations"],
                            };
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: "mandantTexts",
                name: "mandantTexts",
                meta: {
                  parent: () => {
                    return {
                      name: "mandantDashboard",
                    };
                  },
                  title: () => getGlobalTranslation({ message: "pages.mandantText.title" }),
                  docsUrl: () => getLocalizedDocumentationUrl("/mandants/settings/mandanttexts"),
                  activityLogs: () => {
                    return {
                      entities: ["mandanttexts"],
                    };
                  },
                },
                component: () => import("../mandantTexts/views/MandantText_EditView.vue"),
              },
              {
                path: "guests",
                component: () => import("../guests/views/_Guests_View.vue"),
                children: [
                  {
                    path: "list",
                    name: "guestsList",
                    component: () => import("../guests/views/Guests_ListView.vue"),
                    meta: {
                      title: () => {
                        return getGlobalTranslation({ message: "pages.guests.list.title" });
                      },
                      docsUrl: () => getLocalizedDocumentationUrl("/mandants/guests"),
                      parent: () => {
                        return {
                          name: "mandantDashboard",
                        };
                      },
                      activityLogs: () => {
                        return {
                          entities: ["guests"],
                        };
                      },
                    },
                  },
                  {
                    path: ":guestId",
                    name: "guestEdit",
                    component: () => import("../guests/views/Guests_EditView.vue"),
                    meta: {
                      title: () => {
                        return getGlobalTranslation({ message: "pages.edit" });
                      },
                      docsUrl: () => getLocalizedDocumentationUrl("/mandants/guests"),
                      parent: () => {
                        return {
                          name: "guestsList",
                        };
                      },
                      activityLogs: (currentRoute) => {
                        return {
                          entities: ["guests"],
                          entityPrimaryKeys: convertStringToStringArray(currentRoute.params.guestId),
                        };
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/widgetDefinitions",
        component: () => import("../views/_WidgetDefinitions_View.vue"),
        children: [
          {
            path: "list",
            name: "widgetDefinitionsList",
            component: () => import("../views/WidgetDefinition_ListView.vue"),
            meta: {
              title: () => {
                return getGlobalTranslation({ message: "pages.widgetDefinition.list.title" });
              },
              parent: () => {
                return {
                  name: "adminDashboard",
                };
              },
              activityLogs: () => {
                return {
                  entities: ["widget_definition"],
                };
              },
            },
          },
          {
            path: ":widgetDefinitionId",
            name: "widgetDefinitionEdit",
            component: () => import("../views/WidgetDefinition_EditView.vue"),
            meta: {
              title: (currentRoute) => {
                return getEditViewTitle("widgetDefinitionId", currentRoute);
              },
              parent: () => {
                return {
                  name: "widgetDefinitionsList",
                };
              },
              activityLogs: (currentRoute) => {
                return {
                  entityPrimaryKeys: convertStringToStringArray(currentRoute.params.widgetDefinitionId),
                  entities: ["widget_definition"],
                };
              },
            },
          },
        ],
      },
      {
        path: "/releaseNotes",
        component: () => import("../releaseNote/views/_ReleaseNote_View.vue"),
        children: [
          {
            path: "list",
            name: "releaseNotesList",
            component: () => import("../releaseNote/views/ReleaseNote_ListView.vue"),
            meta: {
              title: () => {
                return getGlobalTranslation({ message: "pages.releaseNotes.releaseNotes.list.title" });
              },
              parent: () => {
                return {
                  name: "adminDashboard",
                };
              },
              activityLogs: () => {
                return {
                  entities: ["release_note"],
                };
              },
            },
          },
          {
            path: ":releaseNoteId",
            name: "releaseNoteEdit",
            component: () => import("../releaseNote/views/ReleaseNote_EditView.vue"),
            meta: {
              title: (currentRoute) => {
                return getEditViewTitle("releaseNoteId", currentRoute);
              },
              parent: () => {
                return {
                  name: "releaseNotesList",
                };
              },
              activityLogs: (currentRoute) => {
                return {
                  entityPrimaryKeys: convertStringToStringArray(currentRoute.params.releaseNoteId),
                  entities: ["release_note"],
                };
              },
            },
          },
          {
            path: "/releaseNotes/releaseNoteTags",
            component: () => import("../releaseNote/views/_ReleaseNoteTag_View.vue"),
            children: [
              {
                path: "list",
                name: "releaseNoteTagsList",
                component: () => import("../releaseNote/views/ReleaseNoteTag_ListView.vue"),
                meta: {
                  title: () => {
                    return getGlobalTranslation({ message: "pages.releaseNotes.tags.list.title" });
                  },
                  parent: () => {
                    return {
                      name: "releaseNotesList",
                    };
                  },
                  activityLogs: () => {
                    return {
                      entities: ["release_note_tag"],
                    };
                  },
                },
              },
              {
                path: ":releaseNoteTagId",
                name: "releaseNoteTagEdit",
                component: () => import("../releaseNote/views/ReleaseNoteTag_EditView.vue"),
                meta: {
                  title: (currentRoute) => {
                    return getEditViewTitle("releaseNoteTagId", currentRoute);
                  },
                  parent: () => {
                    return {
                      name: "releaseNoteTagsList",
                    };
                  },
                  activityLogs: (currentRoute) => {
                    return {
                      entityPrimaryKeys: convertStringToStringArray(currentRoute.params.releaseNoteTagId),
                      entities: ["release_note_tag"],
                    };
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: "/collectiveGroup",
        component: () => import("../views/_CollectiveGroup_View.vue"),
        children: [
          {
            path: "list",
            name: "collectiveGroupList",
            component: () => import("../views/CollectiveGroup_ListView.vue"),
            meta: {
              title: () => {
                return getGlobalTranslation({ message: "pages.collectiveGroup.list.title" });
              },
              parent: () => {
                return {
                  name: "adminDashboard",
                };
              },
              activityLogs: () => {
                return {
                  entities: ["collective_group"],
                };
              },
            },
          },
          {
            path: ":collectiveGroupId",
            name: "collectiveGroupEdit",
            component: () => import("../views/CollectiveGroup_EditView.vue"),
            meta: {
              title: (currentRoute) => {
                return getEditViewTitle("collectiveGroupId", currentRoute);
              },
              parent: () => {
                return {
                  name: "collectiveGroupList",
                };
              },
              activityLogs: (currentRoute) => {
                return {
                  entityPrimaryKeys: convertStringToStringArray(currentRoute.params.collectiveGroupId),
                  entities: ["collective_group"],
                };
              },
            },
          },
        ],
      },
      {
        path: "/user",
        component: () => import("../views/_User_View.vue"),
        children: [
          {
            path: "global/:userId",
            name: "userGlobalEdit",
            component: () => import("../views/User_Global_EditView.vue"),
            meta: {
              title: (currentRoute) => {
                return getEditViewTitle("userId", currentRoute);
              },
              parent: () => {
                return {
                  name: "userGlobalList",
                };
              },
              activityLogs: (currentRoute) => {
                return {
                  entityPrimaryKeys: convertStringToStringArray(currentRoute.params.userId),
                  entities: ["users"],
                };
              },
            },
          },
          {
            path: "global/list",
            name: "userGlobalList",
            component: () => import("../views/User_Global_ListView.vue"),
            meta: {
              title: () => {
                return getGlobalTranslation({ message: "pages.globalUser.list.title" });
              },
              parent: () => {
                return {
                  name: "adminDashboard",
                };
              },
              activityLogs: () => {
                return {
                  entities: ["users"],
                };
              },
            },
          },
          {
            path: "profile",
            name: "userProfile",
            component: () => import("../views/User_ProfileView.vue"),
          },
          {
            path: "list",
            name: "userList",
            component: () => import("../views/User_ListView.vue"),
            meta: {
              title: () => {
                return getGlobalTranslation({ message: "pages.user.list.title" });
              },
              parent: () => {
                return {
                  name: "adminDashboard",
                };
              },
              activityLogs: () => {
                return {
                  entities: ["users"],
                };
              },
            },
          },
          {
            path: ":userId",
            name: "userEdit",
            component: () => import("../views/User_EditView.vue"),
            meta: {
              title: (currentRoute) => {
                return getEditViewTitle("userId", currentRoute);
              },
              parent: () => {
                return {
                  name: "userList",
                };
              },
              activityLogs: (currentRoute) => {
                return {
                  entityPrimaryKeys: convertStringToStringArray(currentRoute.params.userId),
                  entities: ["users"],
                };
              },
            },
          },
        ],
      },
      {
        path: "/voucherTemplates",
        component: () => import("../voucher/views/_VoucherTemplates_View.vue"),
        children: [
          {
            path: "list",
            name: "voucherTemplatesList",
            component: () => import("../voucher/views/VoucherTemplates_ListView.vue"),
            meta: {
              title: () => {
                return getGlobalTranslation({ message: "pages.voucher.template.list.title" });
              },
              parent: () => {
                return {
                  name: "adminDashboard",
                };
              },
              activityLogs: () => {
                return {
                  entities: ["voucher_template"],
                };
              },
            },
          },
          {
            path: ":voucherTemplateId",
            name: "voucherTemplateEdit",
            component: () => import("../voucher/views/VoucherTemplate_EditView.vue"),
            meta: {
              title: (currentRoute) => {
                return getEditViewTitle("voucherTemplateId", currentRoute);
              },
              parent: () => {
                return {
                  name: "voucherTemplatesList",
                };
              },
              activityLogs: (currentRoute) => {
                return {
                  entityPrimaryKeys: convertStringToStringArray(currentRoute.params.voucherTemplateId),
                  entities: ["voucher_template"],
                };
              },
            },
          },
        ],
      },
      {
        path: "/portals",
        component: () => import("../views/_Portals_View.vue"),
        children: [
          {
            path: "list",
            name: "portalsList",
            component: () => import("../views/Portals_ListView.vue"),
            meta: {
              title: () => {
                return getGlobalTranslation({ message: "pages.portal.list.title" });
              },
              parent: () => {
                return {
                  name: "adminDashboard",
                };
              },
              activityLogs: () => {
                return {
                  entities: ["portals"],
                };
              },
            },
          },
          {
            path: "/portals/:portalId",
            component: () => import("../views/_Portal_View.vue"),
            props(route) {
              return {
                portalId: parseInt(route.params.portalId as string),
              };
            },
            children: [
              {
                path: "dashboard",
                name: "portalDashboard",
                component: () => import("../views/Portal_DashboardView.vue"),
                meta: {
                  title: () => {
                    return usePortal().currentPortal.value?.name;
                  },
                  parent: () => {
                    return {
                      name: "portalsList",
                    };
                  },
                  activityLogs: (currentRoute) => {
                    return {
                      entityPrimaryKeys: convertStringToStringArray(currentRoute.params.portalId),
                      entities: ["portals"],
                    };
                  },
                },
              },
              {
                path: "edit",
                name: "portalEdit",
                component: () => import("../views/Portal_EditView.vue"),
                meta: {
                  title: (currentRoute) => {
                    return getEditViewTitle("portalId", currentRoute);
                  },
                  parent: () => {
                    if (parseInt(router.currentRoute.value.params.portalId.toString()) === 0) {
                      return {
                        name: "portalsList",
                      };
                    } else {
                      return {
                        name: "portalDashboard",
                      };
                    }
                  },
                  activityLogs: (currentRoute) => {
                    return {
                      entityPrimaryKeys: convertStringToStringArray(currentRoute.params.portalId),
                      entities: ["portals"],
                    };
                  },
                },
              },
              {
                path: "urls",
                component: () => import("../views/_Urls_View.vue"),
                children: [
                  {
                    path: "list",
                    name: "portalUrlsList",
                    component: () => import("../views/Portal_Urls_ListView.vue"),
                    meta: {
                      title: () => {
                        return getGlobalTranslation({ message: "pages.portalUrl.list.title" });
                      },
                      parent: () => {
                        return {
                          name: "portalDashboard",
                        };
                      },
                      activityLogs: () => {
                        return {
                          entities: ["portalurls"],
                        };
                      },
                    },
                  },
                  {
                    path: ":portalUrlId/edit",
                    name: "portalUrlEdit",
                    component: () => import("../views/Portal_Url_EditView.vue"),
                    meta: {
                      title: (currentRoute) => {
                        return getEditViewTitle("portalUrlId", currentRoute);
                      },
                      parent: () => {
                        return {
                          name: "portalUrlsList",
                        };
                      },
                      activityLogs: (currentRoute) => {
                        return {
                          entityPrimaryKeys: convertStringToStringArray(currentRoute.params.portalUrlId),
                          entities: ["portalurls"],
                        };
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "globalMandantTexts",
        name: "globalMandantTexts",
        meta: {
          parent: () => {
            return {
              name: "adminDashboard",
            };
          },
          title: () => getGlobalTranslation({ message: "pages.mandantText.title" }),
          activityLogs: () => {
            return {
              entities: ["mandanttexts"],
            };
          },
        },
        component: () => import("../mandantTexts/views/GlobalMandantText_EditView.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        name: "pageNotFound",
        component: () => import("../views/PageNotFound_View.vue"),
      },
    ],
  },
  {
    path: "/user",
    component: () => import("../views/_User_View.vue"),
    children: [
      {
        path: "reset_password",
        name: "userResetPassword",
        component: () => import("../views/User_ResetPasswordView.vue"),
        meta: {
          doesNotRequireAuthentication: true,
          activityLogs: () => {
            return undefined;
          },
        },
      },
    ],
  },
];

if (import.meta.env.MODE === "development" || import.meta.env.MODE === "staging") {
  routes.push({
    path: "/showroom",
    name: "showroom",
    component: () => import("../views/ComponentShowroom.vue"),
    meta: {
      doesNotRequireAuthentication: true,
      activityLogs: () => {
        return undefined;
      },
    },
  });
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, _, next) => {
  const { initialPingDone } = useApp();
  const { ping, redirectUserToHome } = useUser();

  const currentPopup = Swal.getPopup();
  if (currentPopup?.classList.contains("swal2-modal")) {
    Swal.close();
  }

  if (!to.meta.doesNotRequireAuthentication) {
    try {
      if (!initialPingDone.value) {
        await ping();
      }
    } catch {
      if (to.name === "home" || to.name === "logout") {
        router.push({ name: "login" });
      } else if (to.name !== "login") {
        router.push({ name: "login", query: { redirectPath: to.fullPath } });
      }
    }

    if (mustEnableTwoFactorAuth()) {
      setToastMessage({ icon: "warning", title: getGlobalTranslation({ message: "message.enableTwoFactorAuth" }) });
      if (to.name !== "userProfile") {
        return next({ name: "userProfile" });
      }
    }

    const { isAuthenticated } = useUser();
    if (isAuthenticated.value === true && to.name === "login") {
      await redirectUserToHome();
    }

    return next();
  }

  return next();
});

router.afterEach(async () => {
  if (isRefreshNeeded.value) {
    await updateServiceWorker();
  }
});

function convertStringToStringArray(entityStringOrStringArray: string | string[]): string[] {
  return typeof entityStringOrStringArray === "string" ? [entityStringOrStringArray] : entityStringOrStringArray;
}

function mustEnableTwoFactorAuth(): boolean {
  const { isAuthenticated, isTwoFactorEnabled, userType } = useUser();

  return (
    isAuthenticated.value === true &&
    (userType.value === UserType.ADMIN || userType.value === UserType.SUPER_ADMIN) &&
    isTwoFactorEnabled.value === false
  );
}

function getEditViewTitle(parameter: string, currentRoute: RouteLocation) {
  const id = Number(currentRoute.params[parameter] as string);
  if (id === 0) {
    return getGlobalTranslation({ message: "pages.new" });
  } else if (id > 0) {
    return id + " - " + getGlobalTranslation({ message: "pages.edit" });
  } else {
    return getGlobalTranslation({ message: "pages.edit" });
  }
}

function getLocalizedDocumentationUrl(documentationSubPath: string) {
  // currently, the documentation is only available in german
  return `${config.documentationBaseUrl}/de${documentationSubPath}`;
}

export default router;
